import {Component, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-p404',
    templateUrl: './p404.component.html',
    styleUrls: ['./p404.component.scss'],
})
export class P404Component {

}
