import { Pipe, PipeTransform } from '@angular/core';
import {DateTime} from "luxon";

@Pipe({
  name: 'timeOnly'
})
export class TimeOnlyPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
      const time  = DateTime.fromISO(value).setLocale('ar');
    return time.toFormat('TIME_SIMPLE');
  }

}
